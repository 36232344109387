import React, { createContext } from "react";

import AuthWrapper from "@/components/AuthWrapper";
// import { getChakraTheme } from "@/lib/chakra/theme";
import store from "@/lib/store";
import { GoogleAnalytics } from "nextjs-google-analytics";

import "../public/style.css";
import "../public/print.css";

import AppWrapper from "@/components/utils/AppWrapper";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import Head from "next/head";
import { Provider } from "react-redux";

import { AppPropsWithLayout } from "../lib/types";

type ThemeContextProps = {
  activeTheme: Record<string, any> | undefined;
  setActiveTheme:
    | React.Dispatch<React.SetStateAction<Record<string, any>>>
    | undefined;
};

export const ThemeContext = createContext<ThemeContextProps>({
  activeTheme: undefined,
  setActiveTheme: undefined,
});

function App({
  Component,
  pageProps: { session, ...pageProps },
  router,
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <Provider store={store}>
      <Head>
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width,height=device-height,maximum-scale=1"
        />
      </Head>
      <AuthWrapper router={router}>
        <AppWrapper
          getLayout={getLayout}
          Component={Component}
          pageProps={pageProps}
        />
        {process.env.NODE_ENV === "production" ? (
          <>
            <SpeedInsights />
            <Analytics />
            <GoogleAnalytics {...pageProps} />
          </>
        ) : (
          <></>
        )}
      </AuthWrapper>
    </Provider>
  );
}

export default App;
