import { IClient } from "@/lib/interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUser } from "../services/authApi";

export interface AuthSliceState {
  accessToken: string;
  user: IUser;
  authState: "IDLE" | "LOADING";
}

type AuthType = {
  accessToken: string;
  user: IUser;
  authState: "IDLE" | "LOADING";
};

const initialState: AuthType = {
  accessToken: "",
  user: {},
  authState: "LOADING",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAccessToken(
      state: AuthSliceState,
      action: PayloadAction<{ accessToken: string }>
    ) {
      state.accessToken = action.payload.accessToken;
    },
    setUser(
      state: AuthSliceState,
      action: PayloadAction<{ user: IUser; company: IClient | undefined }>
    ) {
      const { company, user } = action.payload;

      state.user = { ...user, company };
    },
    userLogout: () => initialState,
    setAuthState(
      state: AuthSliceState,
      action: PayloadAction<{ authState: "IDLE" | "LOADING" }>
    ) {
      state.authState = action.payload.authState;
    },
  },
});

export const { userLogout, setAccessToken, setUser, setAuthState } =
  userSlice.actions;

export default userSlice.reducer;
